var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"home"}},[_vm._m(0),_c('section',{staticClass:"banner"},[_c('img',{staticClass:"banner__img",attrs:{"src":require("./assets/new/banner.jpg")}}),_c('div',{staticClass:"check_box"},[_c('div',{staticClass:"inner_box"},[_c('div',{staticClass:"title"},[_vm._v("偶藏鉴定认证查询")]),_c('div',{staticClass:"check_form"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value),expression:"value"}],attrs:{"placeholder":"请输入偶藏鉴定认证编码"},domProps:{"value":(_vm.value)},on:{"input":function($event){if($event.target.composing)return;_vm.value=$event.target.value}}}),(_vm.value)?_c('router-link',{attrs:{"tag":"a","target":"_blank","to":{ name: 'result', params: { code: _vm.value } }}},[_c('div',{staticClass:"btn"},[_vm._v(" 查"),_c('span',{staticStyle:{"display":"inline-block","width":"20px"}}),_vm._v("询 ")])]):_c('div',{staticClass:"btn"},[_vm._v(" 查"),_c('span',{staticStyle:{"display":"inline-block","width":"20px"}}),_vm._v("询 ")])],1)])])]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_c('footer',{staticClass:"ft",attrs:{"id":"contacts"}},[_c('div',{staticClass:"container"},[_vm._m(9),_c('div',{staticClass:"ft__d"},[_c('span',[_vm._v("Copyright 2020.All Rights Reserved. 偶藏 版权所有")]),_c('span',[_c('router-link',{staticStyle:{"margin-right":"20px"},attrs:{"tag":"a","target":"_blank","to":{ name: 'pp' }}},[_vm._v("隐私保护")]),_vm._m(10),_c('a',{attrs:{"href":"https://beian.miit.gov.cn/","target":"_blank"}},[_vm._v("渝ICP备19008599")])],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"hd"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"logo"},[_c('h1',{staticClass:"logo__name"},[_vm._v("偶藏")]),_c('img',{staticClass:"logo__img",attrs:{"src":require("./assets/logo.png")}})]),_c('nav',{staticClass:"nav"},[_c('ul',{staticClass:"nav__list"},[_c('li',{staticClass:"nav__item"},[_c('a',{staticClass:"nav__item__link",attrs:{"href":"#app"}},[_vm._v("APP")])]),_c('li',{staticClass:"nav__item"},[_c('a',{staticClass:"nav__item__link",attrs:{"href":"#features"}},[_vm._v("功能介绍")])]),_c('li',{staticClass:"nav__item"},[_c('a',{staticClass:"nav__item__link",attrs:{"href":"#contacts"}},[_vm._v("联系我们")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"info_box",attrs:{"id":"features"}},[_c('div',{staticClass:"info_title"},[_c('div',[_vm._v("BRAND")]),_c('div',[_vm._v("INTRODUCATION")]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"info_name"},[_vm._v("品牌概况")])]),_c('div',{staticClass:"info_bottom"},[_c('div',{staticClass:"info_item"},[_c('div',{staticClass:"section_title"},[_vm._v("品牌简介")]),_c('div',{staticClass:"info_inner_item"},[_c('div',{staticClass:"info_con"},[_vm._v(" 重庆津南之南网络科技有限公司成立于2016年,坐落于山城重庆。公司专注于体育收藏品和纪念品交易、亲笔签名鉴定，以及体育收藏品文化推广。自成立至今,公司已成功推出偶藏app、偶藏交易小程序、偶藏官方拍卖、偶藏鉴定等业内知名品牌,成为国内体育收藏品市场领导者。 ")]),_c('img',{staticClass:"info_img1",attrs:{"src":require("./assets/new/pppp.png")}})])]),_c('div',{staticClass:"info_item"},[_c('div',{staticClass:"section_title"},[_vm._v("运营模式")]),_c('div',{staticClass:"info_inner_item"},[_c('div',{staticClass:"info_con"},[_vm._v(" 以偶藏app和偶藏交易小程序为根基,提供用户展示、交流和交易功能,通过论坛和资料库功能以及微信社群培育和域流量,最后通过偶藏鉴定以及偶藏官方拍卖解决变现,实现生态闭环。 ")]),_c('img',{staticClass:"info_img2",attrs:{"src":require("./assets/new/img1.png")}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"info_box info_box2"},[_c('div',{staticClass:"description"},[_vm._v(" 重庆津南之南网络科技有限公司旗下核心产品，国内领先的专注于体育收藏品和纪念品交易、亲笔签名鉴定，以及体育收藏品文化推广等综合性服务的app,为用户提供丰富功能和内容展示。 ")]),_c('div',{staticClass:"info_title",staticStyle:{"display":"flex","flex-direction":"column","align-items":"flex-end"}},[_c('div',[_vm._v("ABOUT")]),_c('div',[_vm._v("OCANG APPLICATION")]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"info_name"},[_vm._v("偶藏App及偶藏交易小程序")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"info_box info_box3"},[_c('img',{staticClass:"phone_img",attrs:{"src":require("./assets/new/img2.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"info_box info_box4"},[_c('div',[_c('div',{staticClass:"info_title"},[_c('div',[_vm._v("OCANG")]),_c('div',[_vm._v("AUTHENTICITY")]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"info_name"},[_vm._v("偶藏鉴定")])]),_c('div',{staticClass:"section_title"},[_vm._v("什么是偶藏鉴定?")]),_c('div',{staticClass:"des"},[_vm._v(" 偶藏鉴定是一项亲笔签名鉴定服务,旨在解决用户对于偶像亲笔签名的鉴真需求,协助用户提高收藏价值,稳定收藏市场环境。 ")])]),_c('img',{staticClass:"img3",attrs:{"src":require("./assets/new/img3.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"info_box info_box5"},[_c('img',{staticClass:"img4",attrs:{"src":require("./assets/new/img4.png")}}),_c('div',{staticClass:"box_right"},[_c('div',{staticClass:"section_title"},[_vm._v("偶藏鉴定的优势")]),_c('div',{staticClass:"des",staticStyle:{"padding-top":"20px"}},[_vm._v(" 在国内,偶藏鉴定在历史数据、实操经验、数据库搭建等多个维度建立起了完整的鉴定逻辑链条,从而在鉴定的准确度和权威性上都有着明显的行业优势。 ")]),_c('div',{staticClass:"des"},[_vm._v(" 而对标国外的鉴定公司,偶藏鉴定在鉴定速度、服务周期、用户成本、产品质量等多个维度有着明显优势。同时,在一些国外机构并不擅长的鉴定领域,偶藏鉴定则是用户更好的选择。 ")]),_c('div',{staticStyle:{"flex":"1"}}),_c('img',{staticClass:"bottom_img",attrs:{"src":require("./assets/new/img5.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"info_box info_box6"},[_c('div',{staticClass:"section_title"},[_vm._v("特色服务-专业鉴定封盒业务")]),_c('div',{staticClass:"bottom_con"},[_c('div',{staticClass:"wrap_box"},[_c('div',{staticClass:"wrap_item"},[_c('img',{staticClass:"star_img",attrs:{"src":require("./assets/new/star.png")}}),_c('span',[_vm._v("保护心爱的藏品,避免磨损")])]),_c('div',{staticClass:"wrap_item"},[_c('img',{staticClass:"star_img",attrs:{"src":require("./assets/new/star.png")}}),_c('span',[_vm._v("支持在线查询,增强信任背书,提升藏品价值")])]),_c('div',{staticClass:"wrap_item"},[_c('img',{staticClass:"star_img",attrs:{"src":require("./assets/new/star.png")}}),_c('span',[_vm._v("隔绝空气,防止签名墨迹氧化")])]),_c('div',{staticClass:"wrap_item"},[_c('img',{staticClass:"star_img",attrs:{"src":require("./assets/new/star.png")}}),_c('span',[_vm._v("统一规格,便于码放和收藏")])])]),_c('img',{staticClass:"img6",attrs:{"src":require("./assets/new/img6.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"info_box info_box7"},[_c('div',{staticClass:"top_con"},[_c('div',{staticClass:"info_title",staticStyle:{"display":"flex","flex-direction":"column","align-items":"flex-end"}},[_c('div',[_vm._v("OFFICIAL")]),_c('div',[_vm._v("ON-LINE AUCTION")]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"info_name"},[_vm._v("偶藏官方拍卖会")])]),_c('div',{staticClass:"des"},[_vm._v(" 国内领先的专业体育纪念品线上拍卖会，为用户提供寄拍、挂售等多种藏品变现和购买服务。并可通过偶藏App对交易进行查询,为后续收藏或转手交易提供信任背书。 ")])]),_c('div',{staticClass:"bottom_con"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"info_box8"},[_c('div',{staticClass:"inner"},[_c('img',{staticClass:"img_8",attrs:{"src":require("./assets/new/img_8.png")}}),_c('img',{staticClass:"img8",attrs:{"src":require("./assets/new/img8.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ft__u"},[_c('div',{staticClass:"ft__l"},[_c('h3',[_vm._v("Contact Us")]),_c('p',[_vm._v("客服QQ：2587572868")]),_c('p',[_vm._v("E-Mail：ocang666@163.com")]),_c('p',[_vm._v("地址：重庆市渝中区化龙桥嘉金路5号翠湖天地")])]),_c('div',{staticClass:"ft__r"},[_c('p',[_vm._v("关注我们的社交账号")]),_c('div',{staticClass:"ft__r__icons"},[_c('ul',[_c('li',[_c('img',{attrs:{"src":require("./assets/icons/icon-wb.png"),"alt":""}}),_c('span',[_vm._v("微博：偶藏")])]),_c('li',[_c('img',{attrs:{"src":require("./assets/icons/icon-tt.png"),"alt":""}}),_c('span',[_vm._v("头条号：偶藏")])]),_c('li',{staticClass:"_w"},[_c('img',{attrs:{"src":require("./assets/icons/icon-gzh.png"),"alt":""}}),_c('span',[_vm._v("微信公众号：偶藏")])]),_c('li',[_c('img',{attrs:{"src":require("./assets/icons/icon-bj.png"),"alt":""}}),_c('span',[_vm._v("百家号：偶藏")])])]),_c('ul',[_c('li',[_c('img',{attrs:{"src":require("./assets/icons/icon-sh.png"),"alt":""}}),_c('span',[_vm._v("搜狐号：偶藏")])]),_c('li',[_c('img',{attrs:{"src":require("./assets/icons/icon-qq.png"),"alt":""}}),_c('span',[_vm._v("企鹅号：偶藏")])]),_c('li',{staticClass:"_w"},[_c('img',{attrs:{"src":require("./assets/icons/icon-wxq.png"),"alt":""}}),_c('span',[_vm._v("官方微信群：ocang66")])]),_c('li',[_c('img',{attrs:{"src":require("./assets/icons/icon-dy.png"),"alt":""}}),_c('span',[_vm._v("抖音号：kevinoc")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticStyle:{"display":"inline-block","text-decoration":"none","height":"20px","line-height":"20px","margin-right":"20px"},attrs:{"target":"_blank","href":"http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50010302004517"}},[_c('img',{staticStyle:{"float":"left","margin-right":"5px"},attrs:{"src":require("./assets/bei.jpg")}}),_vm._v(" 渝公网安备 50010302004517号 ")])
}]

export { render, staticRenderFns }