<template>
  <div id="home">
    <header class="hd">
      <div class="container">
        <div class="logo">
          <h1 class="logo__name">偶藏</h1>
          <img class="logo__img" src="./assets/logo.png" />
        </div>
        <nav class="nav">
          <ul class="nav__list">
            <li class="nav__item">
              <a class="nav__item__link" href="/">APP</a>
            </li>
            <li class="nav__item">
              <a class="nav__item__link" href="/#features">功能介绍</a>
            </li>
            <li class="nav__item">
              <a class="nav__item__link" href="#contacts">联系我们</a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
    <section class="banner">
      <img class="banner__img" src="./assets/new/banner.jpg" />
    </section>
    <div class="res_box" id="result">
      <div class="c1"></div>
      <div class="c2"></div>
      <div v-if="loading">1</div>
      <template v-else>
        <div class="r_inner" v-if="detail">
          <img src="./assets/new/r1.png" class="r_logo" />
          <div class="r_code_box">
            <div class="r_code">认证编号：{{ code }}</div>
          </div>
          <div class="detail_box">
            <img :style="`background-image: url(${imgUrl})`" class="detail_img" />
            <div style="width: 40px" />
            <div class="row" v-if="detail">
              <div class="col">
                <div class="t1">偶像</div>
                <div class="t2">{{ detail.idolName }}</div>
              </div>
              <div class="col">
                <div class="t1">Neme of Signer</div>
                <div class="t2">
                  {{ detail.idolNameEn ? detail.idolNameEn : '-' }}
                </div>
              </div>
              <div class="col">
                <div class="t1">载体类型</div>
                <div class="t2">{{ detail.carrierType }}</div>
              </div>
              <div class="col">
                <div class="t1">Type of Item</div>
                <div class="t2">
                  {{ detail.carrierTypeEn ? detail.carrierTypeEn : '-' }}
                </div>
              </div>
              <div class="col">
                <div class="t1">认证时间</div>
                <div class="t2">{{ certificationTime }}</div>
              </div>
              <div class="col" v-for="(item, i) in detail.otherContent" :key="i">
                <div class="t1">{{ item.title }}</div>
                <div class="t2">{{ item.content }}</div>
              </div>
              <a class="return_btn1" target="_blank" href="/">查询下一件</a>
            </div>
          </div>
          <div v-html="content" class="p1"></div>
          <!-- <p class="p1">本文件应作为上述签名的真实性证明，偶藏鉴定中心已完成藏品鉴定。经鉴定：藏品保真。</p>
          <p class="p1">签名墨迹包括倾斜、流动、笔压、字母大小和形状，根据我们在整个收藏圈中检查过的已知样本和广泛数据库中的其他典型特征。我们经过深思熟虑后认为签名是真实的。您的藏品已被分配了唯一的认证编号，该编号将上传到我们的数据库中，并可在我们的网站www.Oucangcertificate.com上确认，随时可用。</p>
          <p class="p1">这份认证证书必须印制在我们专有的水印纸上。感谢您考虑委托偶藏鉴定中心认证，并期待在不久的将来为您检查更多的收藏品。</p> -->
          <div class="qr_code_box">
            <img src="./assets/new/qrcode.jpg" class="qr_code" />
            <div class="qr_text">查询二维码</div>
          </div>
        </div>
        <div class="r_inner" v-else>
          <div class="error_msg">该证书不存在</div>
          <a class="return_btn" href="/">重新查询</a>
        </div>
      </template>
    </div>
    <footer class="ft" id="contacts">
      <div class="container">
        <div class="ft__u">
          <div class="ft__l">
            <h3>Contact Us</h3>
            <p>客服QQ：2587572868</p>
            <p>E-Mail：ocang666@163.com</p>
            <p>地址：重庆市渝中区化龙桥嘉金路5号翠湖天地</p>
          </div>
          <div class="ft__r">
            <p>关注我们的社交账号</p>
            <div class="ft__r__icons">
              <ul>
                <li>
                  <img src="./assets/icons/icon-wb.png" alt="" />
                  <span>微博：偶藏</span>
                </li>
                <li>
                  <img src="./assets/icons/icon-tt.png" alt="" />
                  <span>头条号：偶藏</span>
                </li>
                <li class="_w">
                  <img src="./assets/icons/icon-gzh.png" alt="" />
                  <span>微信公众号：偶藏</span>
                </li>
                <li>
                  <img src="./assets/icons/icon-bj.png" alt="" />
                  <span>百家号：偶藏</span>
                </li>
              </ul>
              <ul>
                <li>
                  <img src="./assets/icons/icon-sh.png" alt="" />
                  <span>搜狐号：偶藏</span>
                </li>
                <li>
                  <img src="./assets/icons/icon-qq.png" alt="" />
                  <span>企鹅号：偶藏</span>
                </li>
                <li class="_w">
                  <img src="./assets/icons/icon-wxq.png" alt="" />
                  <span>官方微信群：ocang66</span>
                </li>
                <li>
                  <img src="./assets/icons/icon-dy.png" alt="" />
                  <span>抖音号：kevinoc</span>
                </li>
              </ul>
            </div>
            <!-- <div class="ft__r__qrcode">
              <span>微信公众号：</span>
              <img src="./assets/qrcode-wechat.jpg" alt />
            </div> -->
          </div>
        </div>
        <div class="ft__d">
          <span>Copyright 2020.All Rights Reserved. 偶藏 版权所有</span>
          <span>
            <router-link
              tag="a"
              target="_blank"
              :to="{ name: 'pp' }"
              style="margin-right: 20px"
              >隐私保护</router-link
            >
            <a href="https://beian.miit.gov.cn/" target="_blank"
              >渝ICP备19008599</a
            >
          </span>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'home',
  data() {
    return {
      detail: null,
      code: null,
      content: '',
      loading: true
    }
  },
  computed: {
    imgUrl() {
      if (
        this.detail &&
        this.detail.imageList &&
        this.detail.imageList.length
      ) {
        return 'http://cdn.ocang.com.cn/' + this.detail.imageList[0].image
      }
      return ''
    },
    certificationTime() {
      if (this.detail?.certificationTime) {
        let date = new Date(this.detail.certificationTime)
        let year = date.getFullYear()
        let month = date.getMonth() + 1
        let day = date.getDate()
        let weekDay = date.getDay()
        return `${year}年${month}月${day}日`
      }
      return '-'
    },
  },
  mounted() {
    this.code = this.$route.params?.code
    this.getData()
    this.getText()
  },
  methods: {
    async getData() {
      if (this.code) {
        const res = await axios.get(
          'https://oucang.ocang.com.cn/open/appraisal/cert/detail',
          { params: { no: this.code } }
        ).catch(err => {
          return null
        })
        this.detail = res?.data
        this.loading = false
      }
    },
    async getText() {
      const res = await axios.get(
        'https://oucang.ocang.com.cn/open/protocol/30'
      )
      this.content = (res?.data?.content || '').replace(/<p><\/p>/g, '<br>')
    },
  },
}
</script>

<style lang="stylus">
.res_box {
  width: 100%;
  padding: 60px 0 120px 0;
  background-color: #000000;
  position: relative;
  overflow: hidden;
  min-height: 1000px;
  box-sizing: border-box;

  .c1 {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-color: #fc0001;
    position: absolute;
    left: -100px;
    top: -100px;
  }

  .c2 {
    width: 450px;
    height: 450px;
    border-radius: 50%;
    background-color: #1376f8;
    position: absolute;
    right: -310px;
    bottom: 100px;
  }

  .r_inner {
    width: 1200px;
    margin: auto;

    .error_msg {
      font-size: 22px;
      color: #FFFFFF;
      text-align: center;
      opacity: 0.7;
    }

    .return_btn, .return_btn1 {
      display: block;
      width: 130px;
      color: #ffffff;
      font-size: 20px;
      text-align: center;
      line-height: 40px;
      height: 40px;
      margin-left: 10px;
      border-radius: 8px;
      cursor: pointer;
      transition: all 200ms;
      opacity: 0.7;
      margin: auto;
      border: 1px solid #ffffff;
      margin-top: 50px;

      &:hover {
        opacity: 1
      }
    }

    .return_btn1 {
      border: none;
    }


    .p1 {
      font-size: 16px;
      color: #ffffff;
      padding-top: 50px;
      text-align: justify;
      word-break: break-all;
      word-wrap: break-word;
    }

    .r_logo {
      display: block;
      margin: auto;
    }

    .r_code_box {
      display: flex;
      justify-content: center;
      padding-top: 50px;
    }

    .r_code {
      height: 60px;
      background-color: #FFFFFF;
      font-size: 32px;
      line-height: 60px;
      text-align: center;
      padding: 0 140px;
    }

    .detail_box {
      display: flex;
      align-items: flex-start;
      padding-top: 80px;
      position: relative;
      z-index: 1;
      .detail_img {
        width: 380px;
        height: 380px;
        background-position: center;
        background-size: cover;
        background-color: #1e1e1e;
      }

      .row {
        flex: 1;
        display: flex;
        flex-direction: column;
        .col {
          background-color: #000000;
          display: flex;
          align-items: center;
          .t1,.t2 {
            font-size: 20px;
            box-sizing: border-box;
            padding: 8px 0;
          }
          .t1 {
            color: #2d6db7;
            text-align: justify;
            padding-left: 50px;
            padding-right: 50px;
            width: 260px;
            text-align-last: justify;
            text-align: justify;
            text-justify: inter-ideograph;
          }
          .t2 {
            color: #ffffff;
            flex: 1;
          }
          &:nth-child(2n+1) {
            background-color: #1e1e1e;
          }
        }
      }
    }

    .qr_code_box {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 60px;
      .qr_code {
        width: 135px;
        height: 135px;
        display: block;
      }
      .qr_text {
        font-size: 20px;
        color: #ffffff;
        padding-top: 20px;
      }
    }
  }
}

@media (max-width:1199px) {
  .res_box {

    .c2 {
      width: 400px;
      height: 400px;
      right: -290px;
    }
    .r_inner {
      width: 960px;

      .p1 {
        font-size: 14px;
      }

      .r_logo {
        display: block;
        margin: auto;
        width: 300px;
      }

      .r_code {
        height: 50px;
        line-height: 50px;
        font-size: 28px;
      }

      .detail_box {
        padding-top: 80px;
        .detail_img {
          width: 300px;
          height: 300px;
        }

        .row {
          .col {
            .t1,.t2 {
              font-size: 18px;
            }
            .t1 {
              padding-left: 40px;
              padding-right: 40px;
              width: 240px;
            }
          }
        }
      }
    }
  }
}
</style>
