import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import Home from './Home.vue'
import PP from './PP.vue'
import Result from './Result.vue'

Vue.use(VueRouter)
Vue.config.productionTip = false

const router = new VueRouter({
  mode: 'history',
  base: __dirname,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/pp',
      name: 'pp',
      component: PP
    },
    {
      path: '/result/:code',
      name: 'result',
      component: Result
    }

  ]
})
new Vue({
  render: h => h(App),
  router
}).$mount('#app')
