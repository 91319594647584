<template>
  <div style="padding:50px 100px">
    <div v-if="detail">
        <div style="padding-bottom: 20px; font-family: 宋体; font-weight: 700; font-size: 20px;">{{detail.title}}</div>
        <div v-html="detail.content"></div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'pp',
  data() {
    return {
      detail: null,
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      const res = await axios.get('https://oucang.ocang.com.cn/open/protocol/2')
      this.detail = res?.data
    },
  },
}
</script>
